import React from "react";
import "./footer.css"


const Footer = () => {
    return (

        <div className="footer-container">

            <text className="word">Moonlight Klub Inc</text>

        </div>


    )
}

export default Footer;