import React from "react";
import NavBar from './components/navbar'
import Logo from './components/logo'
import Episodes from "./components/episodes";
import Footer from './components/footer'


function App() {
  return (
    <>
    <NavBar/>
    <Logo/>
    <Episodes/>
    <Footer/>
    </>
  );
}

export default App;
